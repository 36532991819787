import { render, staticRenderFns } from "./GlobalAdmin.vue?vue&type=template&id=b414f804&scoped=true&"
import script from "./GlobalAdmin.vue?vue&type=script&lang=js&"
export * from "./GlobalAdmin.vue?vue&type=script&lang=js&"
import style0 from "./GlobalAdmin.vue?vue&type=style&index=0&id=b414f804&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b414f804",
  null
  
)

export default component.exports